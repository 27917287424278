import * as React from 'react';
import {useEffect} from 'react';

import {Box, Divider, Grid, Typography} from '@mui/material';
import 'tippy.js/dist/tippy.css';

import CategoryWidget from './category/CategoryWidget';
import HistogramWidget from './histogram/HistogramWidget';
import {useTranslation} from "../../providers/TranslationProvider";
import FilterIcon from '@mui/icons-material/FilterList';
import {useSelector} from "react-redux";

import {divTitleStyles} from '../styles/segments';
import {
  MuiDivider
} from "../../views/organizations/scenarios/analysis/styles/treeView";

const getDataAxis = (widget) => {
  const histColors = widget.customHistogramColors;

  if (histColors) {
    return histColors.map((d) => {
      return +d.tick_id;
    });
  }

  return [0, 10, 100, 1000, 10000];
};

const SegmentsWidget = ({widget,isLast=false}) => {
  const colorEnabled = false
  return (
    <Box style={{
      maxHeight: '100%',
      minHeight: '32px',
      overflow: 'auto',
      marginBottom: '5px',
      overflowY: 'hidden',
    }}>
      {widget.type === 'histogram' && (
        <>
          <HistogramWidget
            id={widget.id}
            title={widget.name}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            answerVar={widget.answerVar}
            ticks={[]}
            formatter={(v) => Number(v).toFixed(3)}
            dataAxis={getDataAxis(widget)}
            color={{
              enabled: colorEnabled,
              type: 'simple',
              customColors: [],
            }}
            global={true}
            description={widget.description}
            wrapperProps={{
              description: widget.description,
              expand: !widget.folded_state,
              disabledWidget: false,
              actions: [],
            }}
            weight={widget.weight}
            policyId={widget.policyId}
          />
          {/*<Divider style={{marginTop: '10px'}}/>*/}
        </>
      )}
      {widget.type === 'category' && (
        <>
          <CategoryWidget
            id={widget.id}
            title={widget.name}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            answerVar={widget.answerVar}
            weight={widget.weight}
            policyId={widget.policyId}
            enableSegmentationAggregation={widget.enableSegmentAggregation}
            description={widget.description}
            wrapperProps={{
              expand: !widget.folded_state,
              description: widget.description,
              disabledWidget: false,
              actions: [],
            }}
          />
          {(!isLast)&&<Divider style={{marginTop: '20px', marginLeft: '16px'}}/>}
        </>
      )}
    </Box>
  );
};

const Segments = ({widgets, policyId, height}) => {
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const barChartFilter = useSelector(state => state.app.barChartFilter);
  const [filtersEnable, setFiltersEnable] = React.useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const localSegmentation={...activeSegmentationFilters,...barChartFilter};
    const hasStatisticFilters = activeStatisticFilters && Object.keys(activeStatisticFilters).length > 0 && Object.keys(activeStatisticFilters).some(key => activeStatisticFilters[key].low||activeStatisticFilters[key].high);
    let hasSegmentationFilters = Object.keys(localSegmentation).length > 0 && Object.keys(localSegmentation).some(key => localSegmentation[key].length > 0);
    if (hasSegmentationFilters) {
      hasSegmentationFilters = widgets.findIndex(w => w.type === 'category' && w.column in activeSegmentationFilters) > -1
    }
    setFiltersEnable(hasStatisticFilters || hasSegmentationFilters);
  }, [activeSegmentationFilters, activeStatisticFilters, policyId,barChartFilter]);

  if (widgets?.length) {
    return (
      <Box sx={{
        display:'flex',
        flexDirection: 'column',
        height: '100%',
        flexGrow:1,
        mt:'32px',
        pr:'16px'
      }}>
        <div style={divTitleStyles}>
          <Typography sx={{
            fontSize: 14,
            textTransform: 'uppercase',
            fontWeight:700,
            lineHeight:'17px',
            fontStyle:'normal',
            fontFamily:'Montserrat',
            color:'#212121'
          }}>
            {t('filters')}
          </Typography>
          {filtersEnable &&
            <FilterIcon style={{marginLeft: '5px',width:'18px',height:'18px'}}
                        color='secondary'/>}
        </div>
        <MuiDivider sx={{margin:'0px 0px 0px 16px',borderColor:'#E0E0E0'}}/>
        <Box sx={{
          overflowY: 'auto',
          marginTop: '10px',
          height:'100%',
          maxHeight: '100%'
        }} id={'widget-box-container'}>
          <div>
            {widgets?.map((w, i) => (
              <Grid style={{display: 'inline'}}
                    key={`$segment-${i}-${policyId}`}>
                {w.visible && <SegmentsWidget widget={w} isLast={i===widgets.length-1}/>}
              </Grid>
            ))}
          </div>
        </Box>
      </Box>

    );
  }
  return null;
};
export default Segments;
