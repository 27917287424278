import * as React from 'react';
import {useEffect, useState} from 'react';
import _ from 'lodash';
import {useLoading} from '../../../../providers/LoadingProvider';
import {useDispatch, useSelector} from 'react-redux';
import axiosEngineInstance from "../../../../../api/axios/axiosEngineInstance";
import {status200} from '../../../../../api/status.utils';
import enginePaths from "../../../../../api/enginePaths";
import {Grid} from '@mui/material';
import NivoTreeMap from "./tree/NivoTreeMap";
import {setTreemapLoadingProgress} from "../../../../../store/appSlice";
import BarGraph from "./bars/BarGraph";
import {normalizeString} from "../../../../../utils/text";
import {useTranslation} from "../../../../providers/TranslationProvider";

export default function AnalysisPage(
  {
    activeTab,
    index,
    tabData,
    showProgressBar,
    policyAnalysisVisualizationType
  }) {
  const [treeAggregationData, setTreeAggregationData] = useState([]);
  const [barAggregationData, setBarAggregationData] = useState(null);
  const [policyTopics, setPolicyTopics] = useState(tabData?.config?.policy?.topics);
  const [lastPayload, setLastPayload] = useState(null);
  const [lastBarAggPayload, setLastBarAggPayload] = useState(null);
  const {
    setIsLoading,
    treeMapCallId,
    setTreeMapLoading,
    setTitle,
    setShowProgressBar,
    setProgress,
    setProgressMessage
  } = useLoading();
  const {t} = useTranslation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const categoryFilter = useSelector(state => state.app.segmentationFilter)

  const statisticFilter = useSelector(state => state.app.statisticFilter);

  const similarityQuery = useSelector(state => state.app.similarityQuery);

  const similarityFilter = useSelector(state => state.app.similarityFilter)

  const barChartFilter = useSelector(state => state.app.barChartFilter);

  const activeInsightsFilter = useSelector(state => state.app.insightsFilter[tabData?.config?.policy?.id]);

  const treeMapFilter = useSelector(state => state.app.treeMapFilter);

  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);

  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);


  const dispatch = useDispatch();

  const fetchDataEngine = async (url, payload, updateProgress = false,callId=null) => {
    try {
      let currentProgress = 0
      const response = await axiosEngineInstance.post(url, payload, {
        ...status200,
        onDownloadProgress: (progressEvent) => {
          if (updateProgress&&callId === treeMapCallId[tabData.config.policy.id]) {
            currentProgress += progressEvent.bytes;
            dispatch(setTreemapLoadingProgress({
              id: tabData.config.policy.id,
              progress: currentProgress
            }));
          }
        }
      });
      return response.data;
    } catch (err) {
      console.log("Error fetching data", err);
      return [];
    }
  };


  const fetchEngineTreeMapData = (payload,callId) => fetchDataEngine(enginePaths.aggregate_answers, payload, true,callId);

  const fetchEngineBarData = (payload) => fetchDataEngine(enginePaths.aggregate_topics_segments, payload, false);

  async function loadEngineTreeAggData() {
    if (tabData.config) {
      const answerVar = tabData.config.policy.answerVar;
      const localSegmentationFilter = {...categoryFilter, ...(activeInsightsFilter || {}),...(barChartFilter || {})}
      delete localSegmentationFilter[answerVar]
      Object.keys(treeMapFilter).forEach(key => {
        if (key !== tabData.config.policy.id) {
          Object.assign(localSegmentationFilter, treeMapFilter[key])
        }
      })
      Object.keys(localSegmentationFilter).forEach(key=>{
        if(localSegmentationFilter[key]?.length === 0){
          delete localSegmentationFilter[key];
        }
      })
      const localSimilarityQuery = similarityQuery[tabData.config.policy.id] || ""
      let localSimilarityFilter = {}
      if (localSimilarityQuery) {
        localSimilarityFilter = similarityFilter[tabData.config.policy.id] || {}
      }


      const iconsInPolicyTopics = tabData.config.policy.topics.map((topic) => {
        return topic.icon ? 1 : 0
      }).reduce((acc, curr) => acc + curr) === tabData.config.policy.topics.length;

      let payload = {
        surveyId: tabData.config.collection_name,
        questionId: answerVar,
        audioVariable: tabData.config.policy.audioVar || null,
        weight: tabData.config.weight,
        slice: tabData.config.policy.amountSamples ? tabData.config.policy.amountSamples : 5,
        segmentation: localSegmentationFilter,
        statistics: statisticFilter,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilter,
        pick_closest: parseBoolean(process.env.REACT_APP_PICK_CLOSEST_ANSWER),
        includeIcons: !iconsInPolicyTopics,
        topics: tabData.config.policy.topics,
        customSegments: tabData.config.policy.customSegmentations,
        maxClassificationTopics: tabData.config.policy.maxClassificationTopics,
        size: tabData.config.policy.size,
        region: tabData.config.collection_region || "US"
      }
      if (treeAggregationData?.length > 0 && lastPayload) {
        if (_.isEqual(payload, lastPayload)) {
          return;
        }
      }
      if (analysisVisualizationType[tabData.config.policy.id] !== 'bars') {
        setTitle(t('treemap_loader_title'));
        setShowProgressBar(showProgressBar);
        setProgressMessage('')
        setProgress(0);
        setTreeMapLoading(prevState => ({
          ...prevState,
          [tabData.config.policy.id]: true
        }));
        setLastPayload(payload);
      }

      const callId = Math.floor(Math.random() * 1000) + 1;
      if(!treeMapCallId[tabData.config.policy.id]){
        treeMapCallId[tabData.config.policy.id] = callId;
      }
      let localAggregationData = await fetchEngineTreeMapData(payload,callId);
      if (localAggregationData && Array.isArray(localAggregationData)) {
        localAggregationData = localAggregationData.map((item) => {
          try {
            if (Array.isArray(item[tabData.config.policy.answerVar + "_label"])) {
              item[tabData.config.policy.answerVar + "_label"] = item[tabData.config.policy.answerVar + "_label"].join(", ")
            }
            item["weight"] = item[tabData.config.weight]
          } catch (e) {

          }
          return item;
        });
        setTreeAggregationData(localAggregationData);
      }

      if (analysisVisualizationType[tabData.config.policy.id] !== 'bars') {
        setTreeMapLoading(prevState => ({
          ...prevState,
          [tabData.config.policy.id]: false
        }));
      }
      return localAggregationData;
    }
    return [];
  }

  const loadBarsAggData = async () => {
    if (tabData.config && analysisVisualizationType[tabData.config.policy.id] === 'bars' || (!barAggregationData || barAggregationData.data.length === 0)) {
      const answerVar = tabData.config.policy.answerVar;
      const localSegmentationFilter = {...categoryFilter, ...(activeInsightsFilter || {})}
      Object.keys(treeMapFilter).forEach(key => {
        if (key !== tabData.config.policy.id) {
          Object.assign(localSegmentationFilter, treeMapFilter[key])
        }
      });
      delete localSegmentationFilter[answerVar + "_label"]
      const localSimilarityQuery = similarityQuery[tabData.config.policy.id] || ""
      let localSimilarityFilter = {}
      if (localSimilarityQuery) {
        localSimilarityFilter = similarityFilter[tabData.config.policy.id] || {}
      }
      const policyReferenceAggSegment = referenceAggTopicSegment[tabData.config.policy.id] || null
      const iconsInPolicyTopics = tabData.config.policy.topics.map((topic) => {
        return topic.icon ? 1 : 0
      }).reduce((acc, curr) => acc + curr) === tabData.config.policy.topics.length;
      let payload = {
        surveyId: tabData.config.collection_name,
        region: tabData.config.collection_region || "US",
        questionId: answerVar,
        weight: tabData.config.weight,
        segmentation: localSegmentationFilter,
        statistics: statisticFilter,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilter,
        includeIcons: !iconsInPolicyTopics,
        mainTopics: tabData.config.policy.topics,
        segmentAttribute: policyReferenceAggSegment
      }
      if (barAggregationData?.data.length > 0 && lastBarAggPayload) {
        if (_.isEqual(payload, lastBarAggPayload)) {
          return;
        }
      }
      setLastBarAggPayload(payload);
      if (analysisVisualizationType[tabData.config.policy.id] === 'bars') {
        setIsLoading(true);
      }

      let localAggregationData = await fetchEngineBarData(payload);
      if (analysisVisualizationType[tabData.config.policy.id] === 'bars') {
        setIsLoading(false);
      }
      setBarAggregationData(localAggregationData);
      return localAggregationData;
    }
    return {data: [], keys: [], indexBy: "topic"};
  }


  const updateAggData = React.useCallback(async () => {
    if (activeTab === index) {
      await loadEngineTreeAggData();
    }
  }, [categoryFilter, statisticFilter, similarityFilter, treeMapFilter, activeInsightsFilter, activeTab, analysisVisualizationType,barChartFilter]);

  const updateBarAggData = React.useCallback(async () => {
    await loadBarsAggData();
  }, [categoryFilter, statisticFilter, similarityFilter, treeMapFilter, activeInsightsFilter, activeTab, referenceAggTopicSegment, analysisVisualizationType]);

  const fetchAndSetData = async () => {
    if (treeAggregationData?.length === 0) {
      const localAggregationData = await loadEngineTreeAggData();
      if (localAggregationData) {
        const sortedTopics = representativitySortedTopics(
          localAggregationData,
          tabData.config.policy.topics,
          tabData.config.policy.answerVar
        );
        setPolicyTopics(sortedTopics);
      }
    }
  };

  const fetchUpdateAggData = async () => {
    await updateAggData();
  };

  const fetchUpdateBarAggData = async () => {
    await updateBarAggData();
  };

  React.useEffect(() => {
    fetchUpdateAggData();
  }, [updateAggData]);

  React.useEffect(() => {
    fetchUpdateBarAggData();
  }, [updateBarAggData]);

  React.useEffect(() => {
    fetchAndSetData();
  }, []);

  function representativitySortedTopics(localData, policyTopics, answerVar) {
    let localPolicyTopics = policyTopics.map((pt) => {
      let ptData = findTopicInData(pt.topic, answerVar, localData);
      if (ptData) {
        return {
          ...pt,
          representativity: ptData.weight
        }
      } else if (pt.subtopics?.length > 0) {
        let totalRepresentativity = 0;
        pt.subtopics.forEach((st) => {
          let stData = findTopicInData(st.topic, answerVar, localData);
          if (stData) {
            totalRepresentativity += stData.weight;
          }
        });
        return {
          ...pt,
          representativity: totalRepresentativity
        }
      }
      return {
        ...pt,
        representativity: 0
      }
    });
    return localPolicyTopics.sort((a, b) => a.representativity - b.representativity);
  }

  const findTopicInData = (topicName, answerVar, data) => {
    function isTopicInData(dataTopic, policyTopicName) {
      const normalizedDataTopic = normalizeString(dataTopic[answerVar + "_label"])
      const normalizedName = normalizeString(policyTopicName);
      return normalizedDataTopic === normalizedName;
    }

    return data.find((dataTopic) => isTopicInData(dataTopic, topicName))
  }


  function parseBoolean(str) {
    try {
      return JSON.parse(str.toLowerCase());
    } catch (e) {
      console.error('Invalid value for parsing to boolean:', str);
      return null;  // or throw an error, or return a default value, etc.
    }
  }


  useEffect(() => {
    // Function to update window height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const treeHeight = windowHeight - 130;
  const treeMaxHeight = windowHeight - 120;

  return (
    <div
      role='tabpanel'
      hidden={activeTab !== index}
      id={`simple-tabpanel-${tabData.id}`}
      aria-labelledby={`simple-tab-${index}`} style={{width: '100%'}}>

      <Grid container style={{
        minWidth: '60%',
        height: treeHeight,
        maxHeight: treeMaxHeight,
        width: '100%',
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 5,
        position: 'relative',
      }}>
        {policyAnalysisVisualizationType !== 'bars' ? (
          <NivoTreeMap
            data={treeAggregationData}
            policy={tabData.config.policy}
            topics={policyTopics}
            visualizationType={policyAnalysisVisualizationType}
          />
        ) : (
          <BarGraph
            data={barAggregationData}
            policy={tabData.config.policy}
            segmentationVariables={tabData.config.segmentationVars}
          />
        )}
      </Grid>
    </div>
  );
}


